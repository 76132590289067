import React, { useEffect, useState } from "react";
import "./home.css";
import { connect } from "react-redux";
import { changeCategory, changeHeaderStyle } from "../../redux/actions";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
	return {
		...state,
		doors: state.doors.doors,
	};
};
const mapPropsTostate = (dispatch) => ({
	changeCategory: (item) => dispatch(changeCategory(item)),
	changeHeaderStyle: (flag) => dispatch(changeHeaderStyle(flag))
});

function Home(props) {
	const [category, setCategory] = useState([]);
	
	useEffect(() => {
		let catego = [];
		let flag = true;
		if (props.doors && props.doors.length > 0) {
			props.doors.forEach((door, i) => {
				catego.forEach((cat, i) => {
					if (door.category === cat.category) {
						flag = false;
					}
				});
				if (flag) {
					catego.push(door);
				}
				flag = true;
			});
		}
		setCategory(catego);
	}, []);
	return (
		<div className="home-container">
			{category.map((e, i) => {
				return (
					<Link to="/catalog" key={i} 
						className="home-item"
						onClick={() => {
							props.changeCategory(e.category);
							window.scrollTo(0, 0);
							props.changeHeaderStyle(false);
						}}
					>
						<div style={{ position: "relative" }}>
							<img id="home-img" src={e.image} alt="" />
							
						</div>
						<div className="home-category-item">
							<p id="home-name"><span>דלתות כניסה</span><br/> דגמי  {e.category}</p>
						</div>
					</Link>
				);
			})}
		</div>
	);
}
export default connect(mapStateToProps, mapPropsTostate)(Home);
