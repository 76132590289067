import { INSERT, INSER_COLORS ,INSERT_HANDLES } from '../actions';

export const dorsMannage = (state = { doors: null, categories: [], colors:[], handles: [] }, action) => {
      switch(action.type) {
            case INSERT: 
                var categories = [] 
                var flag = false;
                if(action.payload.length > 0){
                    action.payload.forEach((data) => {
                      categories.forEach((category) => {
                            if(data.category === category){
                                  flag = true;
                            }
                      });
                      if(!flag){
                            categories.push(data.category);
                      }
                      flag = false;
                });  
                }  
                return {...state, doors: action.payload, categories: categories};
            case INSER_COLORS: 
                return {...state, colors: action.payload};
            case INSERT_HANDLES: 
                return {...state, handles: action.payload};
            default: return state;
      }
}

