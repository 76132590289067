import React, { useEffect, useState, useRef } from "react";
import "./product.css";
import { connect } from "react-redux";
import { productShow } from "../../redux/actions";


const mapStateToProps = (state) => {
	return {
		...state,
		product: state.product.product || {},
		doors: state.doors.doors || {},
	};
};
const mapPropsToState = (dispatch) => ({
	setProduct: (item) => dispatch(productShow(item)),
});
function Product(props) {
	const popupRef = useRef(null);
	const [product, setProduct] = useState(null);
	useEffect(() => {
		setProduct(props.product);
	}, [props.product]);
	useEffect(() => {
		const checkIfClicked = (e) => {
			if (
				props.flag &&
				popupRef.current &&
				!popupRef.current.contains(e.target)
			) {
				props.setFlag();
			}
		};
		window.addEventListener("mousedown", checkIfClicked);
		return () => {
			window.removeEventListener("mousedown", checkIfClicked);
		};
	}, [props.flag]);
		return (
			<div className="product-sticky">
				<div className="product-container">
					<div className="product" ref={popupRef}>
						<div className="details">
							<p className="name-product">דגם {product && product.name}</p>
							<p id="description">{product && product.description}</p>
						</div>
						<div className="img-product">
							<img id="img-product" src={product && product.image} alt="" />
							<p>
								התמונה להמחשה בלבד. המחיר הסופי לדלת ייקבע לפי מידות
								ומפרט ההזמנה ולאחר מידות סופיות. גוון, סורג וגודל הדלת
								משתנים בהתאם למידות ולכן להמחשה בלבד.
							</p>
						</div>

						<p id="back" onClick={() => props.setFlag()}>
							X
						</p>
					</div>
				</div>
			</div>
		);
}
export default connect(mapStateToProps, mapPropsToState)(Product);
