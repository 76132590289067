import React, { useState, useRef, useEffect } from "react";
import "./header.css";
import { connect } from "react-redux";
import {
	changeHeaderStyle,
	productsScroll,
	changeCategory,
} from "../../redux/actions";
import ReactWatsApp from "react-whatsapp";
import Whatsapp from "../../images/icons/whatsapp.png";
import facebook from "../../images/icons/facebook-icon.svg";
import logo from "../../images/pini-logo.svg";
import { NavLink, withRouter, useLocation } from "react-router-dom";

const mapStateToProps = (state) => {
	return { ...state,
		 headerStyle: state.headerStyle.headerStyle,
		  categories: state.doors.categories || null,
		  category: state.category.category || {},
		 };
};

const mapPropsToState = (dispatch) => ({
	changeHeaderStyle: (flag) => dispatch(changeHeaderStyle(flag)),
	setProductsScroll: (scroll) => dispatch(productsScroll(scroll)),
	changeCategory: (item) => dispatch(changeCategory(item)),
      changeHeaderStyle: (flag) => dispatch(changeHeaderStyle(flag)),
});

function Header(props) {
	const [scroll, setScroll] = useState(props.headerStyle ? 0 : 1);
	const location = useLocation();
	window.onscroll = () => {
		if (props.headerStyle === true) {
			setScroll(window.pageYOffset);
		}
	};
	useEffect(() => {
		setScroll(props.headerStyle ? 0 : 1);
	}, [props.headerStyle]);
	useEffect(() => {
		if (props.location.pathname === "/" && scroll === 0) {
			props.changeHeaderStyle(true);
		}
	}, [props.location]);
	useEffect(() => {
		if (props.location.pathname !== "/") {
			props.changeHeaderStyle(false);
		}
	}, []);
	if (window.screen.width <= 750) {
		return (
			<div className={scroll === 0 ? "header-start" : "header"}>
				<div className="header-items">
					<div className="right">
						<div
							className={scroll === 0 ? "icons-start" : "icons"}
							style={{
								display: location.pathname === "/" ? "" : "none",
							}}
						>
							<div className="icon">
								<ReactWatsApp
									id="whatsapp"
									number="97254-493-6366"
									message={`היי פיני\n הגעתי אליך דרך האתר`}
								>
									<img id="whatsapp-icon" src={Whatsapp} alt="" />
									<p>054-493-6366</p>
								</ReactWatsApp>
								<a
									href="https://www.facebook.com/pinidoors"
									target="_blank"
									rel="noopener"
									style={{ display: "flex", alignItems: "center" }}
								>
									<img id="facebook" src={facebook} alt="" />
								</a>
							</div>
						</div>
						<div className={scroll === 0 ? "logo-start" : "logo"}>
							<NavLink
								exact
								to="/"
								onClick={() => {
									window.scrollTo(0, 0);
									props.changeHeaderStyle(true);
								}}
								activeClassName="active"
							>
								<img
									id={scroll === 0 ? "logo-start" : "logo"}
									src={logo}
									alt=""
								/>
							</NavLink>
						</div>
						<div
							className={
								scroll === 0 ? "navigation-start" : "navigation"
							}
						>
							<NavLink
								exact
								id="nav-item"
								to="/"
								onClick={() => {
									props.changeHeaderStyle(true);
									props.setProductsScroll(0);
								}}
							>
								<p id="nav-item-p">דף הבית</p>
							</NavLink>
							<NavLink
								exact
								id="nav-item"
								to="/handles"
								onClick={() => {
									props.changeHeaderStyle(false);
									window.scrollTo(0, 0);
								}}
								activeClassName="active"
							>
								<p id="nav-item-p">ידיות</p>
							</NavLink>
							<NavLink
								exact
								id="nav-item"
								to="/colors"
								onClick={() => {
									props.changeHeaderStyle(false);
									window.scrollTo(0, 0);
								}}
								activeClassName="active"
							>
								<p id="nav-item-p">צבעים</p>
							</NavLink>
							<NavLink
								exact
								id="nav-item"
								to="/contact"
								onClick={() => props.changeHeaderStyle(false)}
								activeClassName="active"
							>
								<p id="nav-item-p">צור קשר</p>
							</NavLink>
							{/* {props.location.pathname === "/catalog" ? (
								<img
									src={menu}
									onClick={() => props.changeFlagMenu()}
									alt=""
									className="btn-menu"
								/>
							) : null} */}
						</div>
						{location.pathname === "/catalog" && <div className="select">
							<select value={props.category} onChange={({target}) => {
									props.changeCategory(target.value);
									props.setProductsScroll(0);
							}}>
								<option value={""}>בחר קטגוריה</option>
								{props.categories &&
									props.categories.map((e, i) => {
										return (
											<option
												value={e} key={i}
											>
												{e}
											</option>
										);
									})}
							</select>
						</div>}
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={scroll === 0 ? "header-start" : "header"}>
				<div className="header-items">
					<div className="right">
						<div className="icons">
							<div className="icon">
								<ReactWatsApp
									id="whatsapp"
									number="97254-493-6366"
									message={`היי פיני\n הגעתי אליך דרך האתר`}
								>
									<img
										id="whatsapp-icon"
										src={Whatsapp}
										alt=""
										width="30vh"
									/>
									<p>054-493-6366</p>
								</ReactWatsApp>
								<a
									href="https://www.facebook.com/pinidoors"
									target="_blank"
									rel="noopener"
									style={{ display: "flex", alignItems: "center" }}
								>
									<img
										id="facebook"
										src={facebook}
										width="55.5vh"
										alt=""
									/>
								</a>
							</div>
						</div>

						<div
							className={
								scroll === 0 ? "navigation-start" : "navigation"
							}
						>
							<NavLink
								exact
								id="nav-item"
								to="/"
								onClick={() => {
									props.changeHeaderStyle(true);
									props.setProductsScroll(0);
								}}
							>
								<p id="nav-item-p">דף הבית</p>
							</NavLink>
							<NavLink
								exact
								id="nav-item"
								to="/catalog"
								onClick={() => props.changeHeaderStyle(false)}
							>
								<p id="nav-item-p">קטלוג</p>
							</NavLink>
							<NavLink
								exact
								id="nav-item"
								to="/handles"
								onClick={() => props.changeHeaderStyle(false)}
							>
								<p id="nav-item-p">ידיות</p>
							</NavLink>
							<NavLink
								exact
								id="nav-item"
								to="/colors"
								onClick={() => props.changeHeaderStyle(false)}
							>
								<p id="nav-item-p">צבעים</p>
							</NavLink>
							<NavLink
								exact
								id="nav-item"
								to="/contact"
								onClick={() => props.changeHeaderStyle(false)}
							>
								<p id="nav-item-p">צור קשר</p>
							</NavLink>
							{/* {props.location.pathname === "/catalog" ? (
								<img
									src={menu}
									onClick={() => props.changeFlagMenu()}
									alt=""
									className="btn-menu"
									width="30vh"
								/>
							) : null} */}
						</div>
					</div>
					<div className={scroll === 0 ? "logo-start" : "logo"}>
						<NavLink
							exact
							to="/"
							onClick={() => {
								window.scrollTo(0, 0);
								props.changeHeaderStyle(true);
							}}
						>
							<img
								id={scroll === 0 ? "logo-start" : "logo"}
								src={logo}
								alt=""
							/>
						</NavLink>
					</div>
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps, mapPropsToState)(withRouter(Header));
