import React, { useEffect } from "react";
import "./App.css";
import Lottie from "lottie-react-web";
import lottieData from "./components/Lottie/loading.json";
import { connect } from "react-redux";
import {
	insert,
	insertColors,
	insertHandles,
	productFlag,
} from "./redux/actions";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import colors from "./colors.json";
import doors from "./doors.json";
import handles from "./handles.json";

import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Products from "./components/Products/Products";
import Product from "./components/Product/Product";
// import NavbarPhone from "./components/Navbar/NavbarPhone";
import { Switch, Route, useLocation } from "react-router-dom";
import Home from "./components/Home/Home";
import Colors from "./components/Colors/Colors";
import ColorsPhone from "./components/Colors/ColorsPhone";
import Handles from "./components/Handles/Handles";
import Contact from "./components/Contact/Contact";

const mapStateToProps = (state) => {
	//func to map redux state in props
	return {
		...state,
		flag: state.product.flag,
		doors: state.doors.doors,
		handles: state.doors.handles,
		colors: state.doors.colors
	};
};
const mapPropsToState = (dispatch) => ({
	// func to map dispatch function to props
	insert: (item) => dispatch(insert(item)),
	insertColors: (item) => dispatch(insertColors(item)),
	setHandles: (item) => dispatch(insertHandles(item)),
	setFlag: () => dispatch(productFlag()),
});

function App(props) {
	const width = window.innerWidth;
	const location = useLocation();

	const createData = (data, type) => {
		let dataNew = [];
		switch (type) {
			case "handles": {
				data.forEach((e) => {
					handles.forEach((ele) => {
						if (e.id === ele.id.toString()) {
							let item = {
								id: ele.id,
								image: e.image,
								model: ele.model,
								name: ele.name,
							};
							dataNew.push(item);
						}
					});
				});
				return dataNew;
			}
			case "doors": {
				data.forEach((e) => {
					doors.forEach((ele) => {
						if (e.id === ele.id.toString()) {
							let item = {
								id: ele.id,
								name: ele.name,
								image: e.image,
								description: ele.description,
								category: ele.category,
							};
							dataNew.push(item);
						}
					});
				});
				return dataNew;
			}
			case "colors": {
				data.forEach((e) => {
					colors.forEach((ele) => {
						if (e.id === ele.id.toString()) {
							let item = {
								id: ele.id,
								image: e.image,
								descriptionName: ele.descriptionName,
								name: e.name,
								background: e.background,
								color: e.color,
							};
							dataNew.push(item);
						}
					});
				});
				return dataNew;
			}
		}
	};

	useEffect(() => {
		fetch("https://pinchasdoors.co.il/server/api/doors.php", {
			method: "GET",
		})
			.then((res) => res.text())
			.then((res) => {
				res = JSON.parse(res);
				var updatedData = createData(res.data, "doors");
				props.insert(updatedData);
			})
			.catch((err) => console.log(err));
		fetch("https://pinchasdoors.co.il/server/api/colors.php", {
			method: "GET",
		})
			.then((res) => res.text())
			.then((res) => {
				res = JSON.parse(res);
				var updatedData = createData(res.data, "colors");
				props.insertColors(updatedData);
			})
			.catch((err) => console.log(err));
		fetch("https://pinchasdoors.co.il/server/api/handles.php", {
			method: "GET",
		})
			.then((res) => res.text())
			.then((res) => {
				res = JSON.parse(res);
				var updatedData = createData(res.data, "handles");
				props.setHandles(updatedData);
			})
			.catch((err) => console.log(err));
	}, []);
	useEffect(() => {
		if (props.flag) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	}, [props.flag]);

	return (
		<div className="App">
			{!props.doors && (
				<div className="load">
					<Lottie
						options={{
							animationData: lottieData,
							loop: true,
							autoplay: true,
						}}
						width={width > 650 ? 400 : 300}
					/>
				</div>
			)}
			{props.flag && <Product flag={props.flag} setFlag={props.setFlag} />}
			<Header />
			<div className="app-body">
				<TransitionGroup>
					<CSSTransition
						timeout={{ enter: 300, exit: 300 }}
						classNames="fade"
						key={location.pathname}
						mountOnEnter={false}
						unmountOnExit={true}
					>
						<Switch>
							<Route exact path="/" component={() => <Home />} />
							<Route
								exact
								path="/catalog"
								component={() => (
									<div className="body">
										<Navbar />
										<Products />
									</div>
								)}
							/>
							<Route
								exact
								path="/colors"
								component={() => {
									if (width < 750) {
										return <ColorsPhone colors={props.colors} />;
									} else {
										return <Colors colors={props.colors} />;
									}
								}}
							/>
							<Route
								exact
								path="/handles"
								component={() => <Handles handles={props.handles} />}
							/>
							<Route
								exact
								path="/contact"
								component={() => <Contact />}
							/>
							<Route
								path="*"
								component={() => (
									<div className="not-found-container">
										<div className="not-found" />
										<p className="not-found-p">
											{" "}
											שגיאת - 404 <br /> הדף אינו נמצא
										</p>
									</div>
								)}
							/>
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			</div>
]		</div>
	);
}
export default connect(mapStateToProps, mapPropsToState)(App);
