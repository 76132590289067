import { combineReducers } from 'redux';
import {dorsMannage} from "./doors";
import { changeCategoryData } from './category';
import {productData} from './product';
import {mannageHeaderStyle} from './headerStyle';

export default combineReducers({
      doors: dorsMannage,
      category: changeCategoryData,
      product: productData,
      headerStyle: mannageHeaderStyle
})