import React, { useState } from "react";
import "./navbar.css";
import { connect } from "react-redux";
import { changeCategory, productsScroll } from "../../redux/actions";

const mapStateToProps = (state) => {
	return { ...state, categories: state.doors.categories || [],
	category: state.category.category };
};
const mapPropsToStata = (dispatch) => ({
	changeCategory: (item) => dispatch(changeCategory(item)),
	setProductsScroll: (scroll) => dispatch(productsScroll(scroll))
});

function Navbar(props) {
	const [active, setcActive] = useState(props.category);
     
	return (
		<div className="navbar" onMouseDown={() => {
		}}>
                  {/* categories */}
			<p id="nav-item-head" onClick={() => {console.log("p")}}>קטגוריות</p>
			{/* print of categories */}
                  {props.categories.map((e, i) => {
                        // check if nav press
				if (e === active) {
					return (
						<p key={i} id="nav-item-active-category">
							{e}
						</p>
					);
				} else {
                              
					return (
						<p
							key={i}
							id="nav-item-category"
                                          
							onClick={() => {
								props.changeCategory(e);
								setcActive(e);
								props.setProductsScroll(0);
							}}
						>
							{e}
						</p>
					);
				}
			})}
		</div>
	);
}
export default connect(mapStateToProps, mapPropsToStata)(Navbar);
