import React, { useState } from "react";
import "./handles.css";

export default function Handles({ handles }) {
	return (
		<div className="handles-container">
                  <div className="handles-header">
                  <h2 className="h1">הידיות שיששדרגו לכם את הדלת</h2>
				<h5 className="h2">
					כל דלת והידית שלה. דלתות הכניסה יכולות להגיע עם ידיות נעות או
					משיכה בעיצובים, גוונים וסגנונות שונים. <br/>ביחרו את הידית שלכם בהתאם
					לטעם, לפונקציונאליות ולסגנון הבית.
				</h5>
                  </div>
			<div className="handles">
				{handles.map((e, i) => {
                                  return (
						<div key={i} className="handle">
							<h4 className="handle-h1">{e.name}</h4>
							<h5 className="handle-h2">{e.model}</h5>
							<div
								id="handle-img"
								style={{ backgroundImage: `url(${e.image})` }}
							/>
						</div>
					);  
				})}
			</div>
		</div>
	);
}
