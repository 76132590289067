import { PRODUCT, PRODUCTS_SCROLL, PRODUCT_FLAG } from "../actions";
export const productData = (state = { product: {}, productsScroll: 0, flag: false }, action) => {
	switch (action.type) {
		case PRODUCT:
			return { ...state, product: action.payload };
		case PRODUCTS_SCROLL:
			return {...state, productsScroll: action.payload}
		case PRODUCT_FLAG:
			return {...state, flag: !state.flag}
		default:
			return state;
	}
};
