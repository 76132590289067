import React, { useEffect, useState, useRef } from "react";
import "./homeFooter.css";
import { connect } from "react-redux";
import { productFlag, productShow, productsScroll } from "../../redux/actions";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
	return {
		...state,
		doors: state.doors.doors,
	};
};
const mapPropsToState = (dispatch) => ({
	product: (item) => dispatch(productShow(item)),
	setProductsScroll: (scroll) => dispatch(productsScroll(scroll)),
	setFlag: () => dispatch(productFlag())
});
function HomeFooter(props) {
	const [products, setProducts] = useState([]);
	const backRef = useRef(null);
	useEffect(() => {
		if (props.doors && props.doors.length > 0) {
			let countDoors = props.doors.length;
			let count = 3;
			let items = [];
			for (let i = 0; i <= count; i++) {
				let flag = true;
				let num = Math.floor(Math.random() * countDoors);
				items.forEach((e) => {
					if (props.doors[num].name && props.doors[num].name === e.name) {
						flag = false;
					}
				});
				if (flag) {
					items.push(props.doors[num]);
				} else {
					if (items.length < 3) {
						countDoors++;
					}
				}
			}
			setProducts(items);
		}
	}, []);
	return (
		<div className="home-footer">
			<div>
				<p id="p-home-footer">דגמים שאולי יעניינו אותך...</p>
			</div>
			<div className="home-footer-child">
				{products.length > 0
					? products.map((e, i) => {
							return (
								<div
									key={i}
									id="product"
									onClick={() => {
										props.product(e);
										props.setProductsScroll(window.scrollY);
										window.scrollTo(0, 0);
										props.setFlag();
									}}
								>
									<div className="img-footer">
										<img id="img-home-footer" src={e.image} alt="" />
									</div>
									<div className="name-footer">
										<p id="name-home-footer">
											<span>דלת כניסה </span>
											<br />
											דגם - {e.name}
										</p>
									</div>
								</div>
							);
					  })
					: null}
			</div>
		</div>
	);
}
export default connect(mapStateToProps, mapPropsToState)(HomeFooter);
