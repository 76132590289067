import React, { useRef } from "react";
import "./contact.css";
import emailjs from "emailjs-com";

function Contact() {
	const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				"service_99dv309",
				"template_4l9e5or",
				e.target,
				"user_aqT1uN67A9RZMPqrpktio"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		form.current.reset();
	};
	return (
		<div className="contact">
			<div className="bg" />
			<div className="contact-child">
				<div className="information">
					<div className="details-contact">
                                    <p>אימייל: pini17267@gmail.com</p>
                                    <p>פלאפון: 054-493-6366</p>
                              </div>
					
				</div>

				<form className="form" ref={form} onSubmit={sendEmail}>
					<h2>לקבלת הצעת מחיר</h2>
					<div className="inputs">
						<input
							id="input"
							name="email"
							dir="ltr"
							placeholder="YourName@gmail.com"
							required
							pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
						/>
						<input
							id="input"
							name="subject"
							placeholder="נושא"
							pattern="[a-zA-Zא-ת]{4,}"
							required
						/>
					</div>
					<div className="text-eara">
						<textarea
                                          id="textarea"
							rows="4"
							cols="35"
							placeholder="ההודעה שלך..."
							name="message"
						></textarea>
					</div>
					<button type="submit" id="send">
						שלח
					</button>
				</form>
			</div>
		</div>
	);
}
export default Contact;
