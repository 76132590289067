import React, { useEffect, useState } from "react";
import "./colors.css";

export default function ColorsPhone({ colors }) {
	const [back, setBack] = useState(null);
	const [name, setName] = useState(null);
	const [color, setColor] = useState(null);
	useEffect(() => {
		if (colors.length > 0) {
			setBack(colors[0].background);
			setName(colors[0].descriptionName);
			setColor(colors[0].color);
		}
	}, [colors]);
	if (back !== null) {
		return (
			<div
				className="colors-container-p"
				style={{ backgroundColor: back ? back : "" }}
			>
				<h4
					style={{
						color: color,
						textAlign: "start",
						fontWeight: "normal",
						width: "100%",
					}}
				>
					דלת אחת. מבחר גוונים
				</h4>
				<h2 style={{ color: color, marginBottom: "3vw" }} id="name-color-p">
					{name ? name : null}
				</h2>
				<div className="colors-p">
					{colors.map((item, i) => {
						return (
							<div
								style={{
									border:
										name === item.descriptionName
											? "6px solid white"
											: "",
									boxSizing: "border-box",
								}}
								key={i}
								className="color-p"
								onClick={() => {
									setBack(item.background);
									setName(item.descriptionName);
									setColor(item.color);
								}}
							>
								<div
									className="color-img-p"
									style={{ backgroundImage: `url(${item.image})`, display: "flex", alignItems: "center", justifyContent: "center" }}
								/>
								<p style={{ color: item.color, position: "absolute" }}>
									{item.name}
								</p>
							</div>
						);
					})}
				</div>
				<p
					style={{
						fontSize: "2.2vh",
						width: "90%",
						wordBreak: "break-word",
						color: color,
						textAlign: "start",
						marginTop: "3vw"
					}}
				>
					הגוונים להמחשה בלבד. ייתכנו שינויים בגוון הסופי של הדלת. <br />
					יש לבחור גוון סופי לאחר ביצוע ההזמנה וממניפת צבע מקורית של
					רב-בריח <br />
					גוון עם סיומת D – בגימור מגורען <br />
					גוון עם סיומת T – בגימור טקסטורה
				</p>
			</div>
		);
	}else{
		return (
			null
		)
	}
}
