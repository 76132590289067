import React, { useEffect, useState} from "react";
import "./products.css";
import { connect } from "react-redux";
import HomeFooter from "../Home-Footer/HomeFooter";
import { productFlag, productShow, productsScroll } from "../../redux/actions";
import Lottie from "lottie-react-web";
import lottieData from '../Lottie/loading.json';
const mapStateToProps = (state) => {
	return {
		...state,
		category: state.category.category || {},
		doors: state.doors.doors || {},
		categories: state.doors.categories || {},
		scroll: state.product.productsScroll || {},
	};
};

const mapPropsToState = (dispatch) => ({
	product: (item) => dispatch(productShow(item)),
	setProductsScroll: (scroll) => dispatch(productsScroll(scroll)),
	setFlag: () => dispatch(productFlag()),
});

function Products(props) {
	const width = window.innerWidth;
	const [doorsToPrint, setDoorsToPrint] = useState(null);
	
	useEffect(() => {
		setDoorsToPrint(props.doors);
	}, [props.doors]);
	useEffect(() => {
		console.log("filter");
		if (props.category.length > 0) {
			let doors = props.doors.filter((e) => e.category == props.category);
			setDoorsToPrint(doors);
		}
	}, [props.category]);
	useEffect(() => {
		window.scrollTo(0,props.scroll);
	},[]);
	if(doorsToPrint === null) {
		return (
			<div className="load">
			<Lottie
					options={{
						animationData: lottieData,
						loop: true,
						autoplay: true	
					}}
					width={width > 650 ? 400 : 300}
				/>
		</div>
		)
	}
	else{
		return (
		<div className="products-container">
			<div className="doors-container">
				{doorsToPrint.length > 0
					? doorsToPrint.map((e, i) => {
							return (
								<div
									key={i}
									className="products-item"
									onClick={() => {
										props.product(e);
										props.setProductsScroll(window.scrollY);
										window.scrollTo(0, 0);
										props.setFlag();
									}}
								>
									<div className="products-img">
										<img id="products-img" src={e.image} alt="" />
									</div>
									<div className="products-name">
										<p id="products-name"><span>דלת כניסה </span><br/>דגם - {e.name}</p>
									</div>
								</div>
							);
					  })
					: null}
			</div>
			<div className="products-footer">
				<HomeFooter />
			</div>
		</div>
	);
	}
}
export default connect(mapStateToProps, mapPropsToState)(Products);
