export const INSERT = 'INSERT';

export const INSER_COLORS = 'INSERT_COLORS';

export const INSERT_HANDLES = "INSERT_HANDLES";

export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';

export const PRODUCT = "PRODUC";

export const HEADER_STYLE = 'HEADER_STYLE';

export const PRODUCTS_SCROLL = "PRODUCTS_SCROLL";

export const PRODUCT_FLAG = "PRODUCT_FLAG";

export const insert = (data) => {
      return {
            type: INSERT,
            payload: data
      }
}

export const insertColors = (item) => {
      return { type: INSER_COLORS, payload: item}
}

export const insertHandles = (handlesData) => {
      return {type: INSERT_HANDLES, payload: handlesData}
}

export const changeCategory = (category) => {
      return {
            type: CHANGE_CATEGORY,
            payload: category
      }
}

export const productShow = (product) => {
      return {
            type: PRODUCT,
            payload: product
      }
}

export const changeHeaderStyle = (flag) => {
      return {
            type: HEADER_STYLE,
            payload: flag
      }
}

export const productsScroll = (scroll) => {
      return {
            type: PRODUCTS_SCROLL,
            payload: scroll
      }
} 

export const productFlag = () => {
      return {
            type: PRODUCT_FLAG
      }
}