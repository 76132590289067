import React, { useState, useEffect } from "react";
import "./colors.css";

export default function Colors({ colors }) {
	const [back, setBack] = useState(null);
	const [name, setName] = useState(null);
	const [color, setColor] = useState(null);
	useEffect(() => {
		if (colors.length > 0) {
			setBack(colors[0].background);
			setName(colors[0].descriptionName);
			setColor(colors[0].color);
		}
	}, [colors]);
	if(back !== null){
		return (
			<div
				className="colors-container"
				style={{ backgroundColor: back ? back : "" }}
			>
				<div className="colors">
					{colors.map((color, i) => {
						return (
							<div
								style={{
									border: color.descriptionName === name ? "4px solid white" : '', boxSizing: 'border-box',
									backgroundImage: `url(${color.image})`
								}}
								key={i}
								className="color"
								onClick={() => {
									setBack(color.background);
									setName(color.descriptionName);
									setColor(color.color);
								}}
							>
								<p style={{ color: color.color }}>{color.name}</p>
							</div>
						);
					})}
				</div>
				<div className="color-description-container">
					<div className="color-description">
						<h3
							style={{ color: color, fontWeight: "normal", width: "100%" }}
						>
							דלת אחת. מבחר גוונים
						</h3>
						<h2 style={{ color: color, fontSize: "4.5vh", width: "100%" }}>
							{name ? name : null}
						</h2>
						<p
							style={{
								fontSize: "2.2vh",
								width: "100%",
								wordBreak: "break-word",
								color: color,
							}}
						>
							הגוונים להמחשה בלבד. ייתכנו שינויים בגוון הסופי של הדלת.{" "}
							<br />
							יש לבחור גוון סופי לאחר ביצוע ההזמנה וממניפת צבע מקורית של
							רב-בריח <br />
							גוון עם סיומת D – בגימור מגורען <br />
							גוון עם סיומת T – בגימור טקסטורה
						</p>
					</div>
				</div>
			</div>
		);
	}else{
		return(
			null
		)
	}
}
